import React from 'react';
import { useParams } from 'react-router-dom';
import ReptileTracko from './Porduct';
import MobileApp from './MobileApp';

const ProductPage = () => {
  const { id } = useParams();
  let Component;
  switch (id) {
    case 'reptile_tracko':
      Component = ReptileTracko;
      break;
    case 'enterprise-mobility-management':
      Component = MobileApp;
      break;
    
  }

  return <Component />;
};

export default ProductPage;