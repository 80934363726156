import React, { useEffect } from 'react';

const MessageModal = ({ message, onClose }) => {
    useEffect(() => {
        if (message.visible) {
            const timer = setTimeout(() => {
                onClose();
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message.visible) return null;
    const handleCloseModal = () => {
        // Hide the modal after 5 seconds
        onClose();
    };

    return (
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
        }}>
            <img
                src="/assets/HomeIimages/Reptile_R_Logo/Reptile Logo-01.png"
                alt=""
                style={{ width: 180, marginLeft: 237, marginTop: 10, marginBottom: 10 }}
            />
            <h4 style={{ color: message.type === 'success' ? 'green' : 'red' }}>
                {message.text}
            </h4>

            <button
                className="btn btn-danger"
                onClick={handleCloseModal}
                style={{
                    paddingLeft: 30,
                    marginLeft: "38%",
                    marginRight: "40%",
                    marginTop: "3%",
                    paddingTop: 10,
                    paddingRight: 42,
                    paddingBottom: 10
                }}
            >
                OK
            </button>
        </div>
    );
};

export default MessageModal;
