
import Navbar from "./Navbar";
import Footer from "./footer";
import './index.css'
const MobileApp = ()=>{
    return(
        <div>
            <Navbar />
            <div className="MobileAppSection">
          <div className="container">
            <div className="row">
                <div className="col-6 MobileApp_text_section"style={{padding:'0',margin:'0'}}>
                  <h2>Enterprise <br></br>Mobility Management Solution by Reptile India</h2>
                  <h6>Manage Enterprise Devices Seamlessly</h6>
                  <p>Businesses are embracing mobile technology more and more to improve productivity and streamline operations in today's fast-paced digital world. At Reptile India, we recognize how crucial it is to secure and manage mobile devices in a work setting. With a suite of tools to optimize, secure, and manage mobile ecosystems, our Enterprise Mobility Management (EMM) Solution empowers enterprises.</p>
                  <div>
                 <button>Request a Demo</button>
                  </div>
                 
                </div>
                <div className="col-6 Images_section_mobileApp"style={{padding:'0',margin:'0'}}>
                  <div className="images_over_section">
                    
                    <div><i class="fa fa-star" aria-hidden="true"style={{color:'orangered'}}></i> Trustpilot</div>
                    <h6>50,125 Great Review</h6>
                  </div>
                  <img src="/assets/GetQueryImages/MobileImages/Banner-2 (1).png" alt="MobileApp"/>
                </div>
            </div>
          </div>
          </div>
          <div className="Feauture_mobile_app_section">
            <div className="container">
              <div className="text_features_section">
                <div className="Heading">
                
              <h2>Why Choose Reptile India’s EMM Solution?</h2>
              <p>Enterprise Mobility Solution</p>
              </div>
              <div className="Psection">
              
              <p>With the powerful device management features that our EMM system provides, IT managers can effectively monitor every mobile device in the company. Reptile India offers end-to-end device management, which guarantees that every device is correctly configured and secured, from provisioning to decommissioning.</p>
            </div>
            </div>
            <div className="row">
              <div className="col-5 Images_section_features"style={{padding:'0',margin:'0'}}>
                <img src="/assets/GetQueryImages/MobileImages/mobile.png" alt="images"/>
              </div>
              <div className="col-7 Images_slide_section"style={{padding:'0',margin:'0'}}>
                <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Bulk Device Enrollment</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/location.png" alt="images"/>
                  <h5>Geofencing/Location Tracking</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/online-payment.png" alt="images"/>
                  <h5>Application Management</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/monetary-policy.png" alt="images"/>
                  <h5>Device Policy Management</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Kiosk Mode</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Device Security</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              
              </div>
            </div>
            </div>
          
          </div>
          <div className="Award_section_top">
          <div className="container Award_images_section">
            <div className="row Award_images_section_mian">
            <div className="text_features_section">
                <div className="Heading">
                
              <h2>Enhanced Security</h2>
            
              </div>
              <div className="Psection">
              
              <p>The foundation of our EMM system is security. We offer several security levels to protect confidential company information and guarantee the safe operation of your mobile workforce</p>
            </div>
            </div>
              <div className="col-6 text_awarod_section"style={{padding:'0',margin:'0'}}>
                <div className="Text_award_section_secound">
                 <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Configure VPN/Wifi settings</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Communicate or send broadcast messages promptly</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Remotely wipe device data</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Monitor Device in real-time</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              </div>
              <div className="col-6 Images_Award_section_left"style={{padding:'0',margin:'0'}}>
                <img src="/assets/GetQueryImages/MobileImages/pagos2024.png" alt="images"/>
              </div>
            </div>
          </div>
          </div>
          
         <div className="container  EMM_Solution_pages_section_top">
         <div className="text_features_section">
                <div className="Heading"style={{width:'49%'}}>
                
              <h2>Application Management</h2>
             
              </div>
              <div className="Psection"style={{width:'40%'}}>
              <p>With Reptile India's EMM solution, manage and deploy apps across all devices with ease. With our platform, IT departments can:</p>
              <p><strong> App Deployment:</strong>Install apps on devices remotely without requiring user input.</p>
              <p><strong>Blacklisting and Whitelisting Apps: </strong> Manage the apps that are permitted or prohibited on devices.</p>
              <p><strong>Version Control: </strong> Make sure the most recent, secure app versions are installed on all devices.</p>

            </div>
            </div>
          <div className="row EMM_Solution_pages_section_top_main">
          <img src="/assets/GetQueryImages/MobileImages/Asset 1.png" alt='images'/>
            <div className="Emm_solution_pages_item">
              <div className="Emm_solution_div_text">
                <div className="First_section_top_emm">
                
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Eliminate the need to update servers, cut down on mobile data usage, and permit business-critical apps.
                  </p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Simplify Process</h5>
                  <p>Use Reptile EMM to enrol devices in bulk and spend less time managing them than with any other EMM solution.</p>
                  </div>
                </div>
              
                <div className="Secound_section_top_emm">
                
        
                <div className="Text_Emm_First_section">
                <h5>Protection Against Factory Reset </h5>
                <p>Allow reputable Google accounts to access the device after it has been factory reset. Always keep your device and data safe.</p>
                </div>
                <div className="Text_Emm_First_section">
                <h5>Restrict Device Tethering </h5>
                <p>Turn off tethering and stop using mobile data when setting up hotspots on your devices.</p>
                </div>
                <div className="Text_Emm_First_section">
                <h5>Disable Outgoing Calls</h5>
                <p>Turn off tethering and stop using mobile data when setting up hotspots on your devices.</p>
                </div>
              </div>
              <div className="Thard_section_top_emm">
                 <div className="Text_Emm_First_section">
                  <h5>Limit Device Restart</h5>
                  <p>Limit the ability for users to reboot their devices and prevent them from changing important business programs or device settings.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Disable USB Storage Access</h5>
                  <p>Remove unauthorised downloads of private information, and designate compliance to meet business standards in order to restrict USB data sharing usage.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Policy Enforcement Rules</h5>
                  <p>Create unique responses for any violation of high-level policies. Android Device Policy automatically disables use of a device or work profile by default if it doesn't follow any of the policy parameters.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Custom Feature</h5>
                  <p>For any custom needs, please get in touch with us.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>

         <div className="container Parcing_section" style={{ marginTop: 200 }}>
          <div className="row">
            <div className="Priceing_section_text">
              <div className="Text_priceing">
                <h2>Our Pricing</h2>
                <p>Choose the right fit for your organization from our flexible and affordable range of pricing plans.</p>
              </div>
              <div className="Button_priceng_section">
              <h5>Binding period</h5>
              <div>
                <button>36 Months</button>
                <button>24 Months</button>
                <button>12 Months</button>
                <button>Months</button>
              </div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$2.49</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Standard Plans</h5>
               <p>Ideal for Small Businesses: Shops & Restaurants with a Need for KIOSK Mode on a Few Devices</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Contact Us</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$3.49</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Enterprise Plans</h5>
               <p>Suitable for organizations of any size that require an economical and complete EMM platform.</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Contact Us</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$4.49</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Corporate Plans</h5>
               <p>Perfect for major enterprises, software providers, and OEMs looking to enable business models.</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Contact Us</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               {/* <h3 style={{color:'orangered'}}>$2.50</h3>
               <h6>per device per month</h6> */}
               <h6  style={{ margin: 9 }} >Didn’t find what you were looking for? Design a custom package that meets your requirements!
               </h6>
               </div>
               <h5>Custom Plan               </h5>
               <p >Custom Plan is Perfect for ISVs, OEMs, and high-volume enterprise rollouts.         </p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Contact Us</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
          </div>
         </div>
            <Footer />
        </div>
    )
}
export default MobileApp;