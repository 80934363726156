import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';
import axios from "axios";


function Career() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [msg_subject, setmsg_subject] = useState("");
  const [message, setmessage] = useState("");

  function handalSubmit() {
    if(!name){
      alert("Name field is required");
      return false;
    }
    if(!email){
      alert("Email field is required");
      return false;
    }
    if(!phone_number){
      alert("PhoneNumber field is required");
      return false;
    }
    if(!msg_subject){
      alert("Subject field is required");
      return false;
    }
    if(!message){
      alert("Message field is required");
      return false;
    }
    axios
      .post("https://www.reptileindia.co.in/laravel_website/api/website_send_email", {
        name: name,
        email:email,
        phone_number:phone_number,
        msg_subject:msg_subject,
        message:message
      })
      .then((res) => {
        console.log("res----------->",res);
        if (res.data.result){
          alert(res.data.message);
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
          window.scrollTo(0, 0);
      }
      });
  }
  //window.scrollTo(0, 0);
  useEffect(() => {
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
      <>
        <>
          <Navbar />
          <>
          <>
  <section
    className="d-flex align-items-center page-hero  inner-page-hero "
    id="page-hero"
    style={{ backgroundImage: 'url("assets/assets/images/Career.jpg")' }}
  >
    <div
      className="overlay-photo-image-bg parallax"
      data-bg-img="assets/assets/images/hero/inner-page-hero.jpg"
      data-bg-opacity={1}
    />
    <div className="overlay-color" data-bg-opacity=".75" />
    <div className="container">
      <div className="hero-text-area centerd">
        <h1 className="hero-title  wow fadeInUp" data-wow-delay=".2s">
          Career
        </h1>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb wow fadeInUp" data-wow-delay=".6s">
            <li className="breadcrumb-item">
              <a className="breadcrumb-link">
                <i className="bi bi-house icon " />
                home
              </a>
            </li>
            <li className="breadcrumb-item active">Career</li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
  {/* End inner Page hero*/}
  {/* Start contact-us */}
  <section className="contact-us  mega-section  pb-0 mt-5" id="contact-us">
    <div className="container">
      <section className="locations-section  mega-section ">
        <div className="sec-heading centered  ">
          <div className="content-area">
            <h2 className=" title    wow fadeInUp" data-wow-delay=".4s">
              our offices around the world
            </h2>
          </div>
        </div>
        <div className=" contact-info-panel ">
          <div className="info-section ">
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="info-panel  wow fadeInUp" data-wow-delay=".4s ">
                  <h4 className="location-title">Delhi</h4>
                  <div className="line-on-side "> </div>
                  <p className="location-address">
                    Carecone Technologies private limited 265, 2nd floor,
                    Aggarwal City Plaza, Plot No.-17, Mangalam Place, Sector-3
                    Rohini, New Delhi-110085
                  </p>
                  <div className="location-card  ">
                    <i className="flaticon-email icon" />
                    <div className="card-content">
                      <h6 className="content-title">email:</h6>
                      <a
                        className="email link"
                      >
                        sales@reptileindia.co.in
                      </a>
                    </div>
                  </div>
                  <div className="location-card">
                    <i className="flaticon-phone-call icon" />
                    <div className="card-content">
                      <h6 className="content-title">phone:</h6>
                      <a className="tel link">
                        Sales : +91 9311572747
                      </a>
                      {/* <a className="tel link">
                        HR : +91 9990908527
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="map-section  elf-section">
        <div className="sec-heading  centered   ">
          <div className="content-area">
            <h2 className=" title    wow fadeInUp" data-wow-delay=".4s">
              find us on google maps
            </h2>
          </div>
        </div>
        <div className="map-box  wow fadeInUp" data-wow-delay=".6s">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="map-iframe"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.6405916506687!2d77.11449817503612!3d28.700395980996475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03c1266e5ae9%3A0x37cacfac2451d3ee!2sCarecone%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1693681346029!5m2!1sen!2sin"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="about mega-section mb-5" id="about">
        <div className="container">
          <div className="content-block" style={{ marginBottom: 0 }}>
            <div className="row">
              <div className="col-12">
                <div className="contact-form-panel">
                  <div className="sec-heading centered    ">
                    <div className="content-area">
                      <h2
                        className=" title    wow fadeInUp"
                        data-wow-delay=".4s"
                      >
                        Have any questions? Let's answer them
                      </h2>
                    </div>
                  </div>
                  <div
                    className="contact-form-inputs wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <div className="custom-form-area input-boxed">
                      {/*Form To have user messages*/}
                      {/* <form
                        className="main-form"
                        id="contact-us-form"
                        action="https://www.reptileindia.co.in/contact-us"
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="nXjYRyWm46wmtHnV4YfGiuDxitjAQdGAYVp4Ng8R"
                        />{" "}
                        <span className="done-msg" />
                        <div className="row ">
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Name"
                                id="user-name"
                                name="name"
                                type="text"
                              />
                              <label
                                className="input-label"
                                htmlFor="user-name"
                              >
                                {" "}
                                Name <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Email"
                                id="user-email"
                                name="email"
                                type="email"
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                E-mail <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Phone"
                                id=""
                                name="phone_number"
                                type="text"
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                Phone<span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Subject"
                                id="msg-subject"
                                name="msg_subject"
                                type="text"
                              />
                              <label
                                className="input-label"
                                htmlFor="msg-subject"
                              >
                                {" "}
                                Subject <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="   input-wrapper">
                              <textarea
                                className=" text-input"
                                placeholder="Enter Your Message"
                                id="msg-text"
                                name="message"
                                defaultValue={""}
                              />
                              <label className="input-label" htmlFor="msg-text">
                                {" "}
                                your message <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <i />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 submit-wrapper">
                            <button
                              className=" btn-solid"
                              id=""
                              type="submit"
                              name="UserSubmit"
                            >
                              Send your message
                            </button>
                          </div>
                        </div>
                      </form> */}
                      <form
                        className="main-form"
                        id="contact-us-form"
                      >
                        <span className="done-msg" />
                        <div className="row ">
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Name"
                                id="user-name"
                                name="name"
                                type="text"
                                value={name}
                                onChange={(e)=>{setname(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-name"
                              >
                                {" "}
                                Name <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Email"
                                id="user-email"
                                name="email"
                                type="email"
                                value={email}
                                 onChange={(e)=>{setemail(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                E-mail <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Phone"
                                id=""
                                name="phone_number"
                                type="text"
                                value={phone_number}
                                onChange={(e)=>{setphone_number(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                Phone<span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Subject"
                                id="msg-subject"
                                name="msg_subject"
                                type="text"
                                value={msg_subject}
                                 onChange={(e)=>{setmsg_subject(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="msg-subject"
                              >
                                {" "}
                                Subject <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="   input-wrapper">
                              <textarea
                                className=" text-input"
                                placeholder="Enter Your Message"
                                id="msg-text"
                                name="message"
                                defaultValue={""}
                                value={message}
                                onChange={(e)=>{setmessage(e.target.value) }}
                              />
                              <label className="input-label" htmlFor="msg-text">
                                {" "}
                                your message <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <i />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 submit-wrapper">
                            <button
                              className=" btn-solid"
                              id=""
                              type="button"
                              name="UserSubmit"
                              onClick={()=>{ handalSubmit() }}
                            >
                              Send your message
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</>

          <>
         
</>

 
</>

        <Footer />
        </>

      </>

    </Fragment>
  )
}
export default Career;