import Aos from "aos";
import Navbar from "../Navbar";
import Footer from "../footer";
import "../index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import MessageModal from '../MessageModal'; // Adjust the import path accordingly

const Contact = () => {
  function scrooltoDown(){
    const element = document.getElementById('element-id'); 
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
  const { location } = useLocation();
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [msg_subject, setmsg_subject] = useState("");
  const [message, setmessage] = useState("");
  const [submitmessage, setsubmitmessage] = useState({ type: '', text: '', visible: false });

  function handalSubmit() {
    if(!name){
      alert("Name field is required");
      return false;
    }
    if(!email){
      alert("Email field is required");
      return false;
    }
    if(!phone_number){
      alert("PhoneNumber field is required");
      return false;
    }
    if(!msg_subject){
      alert("Subject field is required");
      return false;
    }
    if(!message){
      alert("Message field is required");
      return false;
    }
    axios
      .post("https://www.reptileindia.co.in/laravel_website/api/website_send_email", {
        name: name,
        email:email,
        phone_number:phone_number,
        msg_subject:msg_subject,
        message:message
      })
      .then((res) => {
        console.log("res----------->",res);
        if (res.data.result){
          //alert(res.data.message);
          setsubmitmessage({ type: 'success', text: 'Thank you for your submission! If you have any immediate questions or need assistance, don’t hesitate to reach out to us at +91- 9311572747', visible: true });
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
        //  window.scrollTo(0, 0);
          // setTimeout(() => {
          //   setsubmitmessage({ type: '', text: '', visible: false });
          // }, 3000); // 5000ms = 5 seconds
      }
      });
  }
  console.log("submitmessage----------->",submitmessage);

  const handleCloseModal = () => {
    // Hide the modal after 5 seconds
    setsubmitmessage({ type: '', text: '', visible: false });
  };

  //window.scrollTo(0, 0);
  useEffect(() => {
    // window.scrollTo(0, 0);
    Aos.init({
      duration: 1200, // Customize the duration as needed
    });
  }, [location]);

  return (
    <div>
      <Navbar />

      <MessageModal message={submitmessage} onClose={handleCloseModal} />

      <div className="top_header_section">
        <div className="container">
          <div className="row main_images_section">
            <div className="col-lg-6 Contact_images_section">
              <img src="/assets/GetQueryImages/img.4df64db8.svg" alt="images" />
            </div>
            <div className="col-lg-6 text_contact_section">
              <h2>Contact Us</h2>
              <div className="line_contact_section"></div>
              <p>
              Have questions or need more information about our services? Our team at Carecone Technologies Pvt. Ltd. is ready to assist you. Whether you're looking for IT solutions, need support, or want to discuss a project, we'd love to hear from you.
              </p>
              <div className="number_section">
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 9311572747 </h6>
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 8178334747 </h6>
              </div>
              <div className="fa_fa_section">
               <a href='https://www.instagram.com/reptileitservice'><i class="fa fa-instagram" aria-hidden="true"></i></a>
               <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Freptileindia">
                {" "}
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
                <a href="https://api.whatsapp.com/send?phone=+919311572747&text=Hello">
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
                <a href="https://www.linkedin.com/company/reptileitservice/">
                <i className="fa fa-linkedin" aria-hidden="true" />
              </a>
              </div>
              <div className="btn_contact">
                <button onClick={()=>scrooltoDown()} >Get Query</button>
                <button onClick={()=>scrooltoDown()} >Contact</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_section_header" id="element-id">
        <div className="container Address_section">
          <div className="row">
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  {" "}
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="number">
                  <h6>Mobile Number</h6>
                  <h6>+91 9311572747  <span className="number_hide"> +91 8178334747</span></h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                </div>
                <div className="number">
                  <h6>Email</h6> <h6>nidhi@reptileindia.co.in</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  {" "}
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="number">
                  <h6>Website</h6>
                  <h6>www.reptileindia.co.in</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container form_section_main_contact">
          <div className="row">
            <div className="col-lg-6 form_section_text">
              <h2>Contact Us</h2>
              <div className="line_contact"></div>
              <form>
                <div className="form_section_text_main">
                  <div>
                    <label>Full Name</label>
                    <br></br>
                    <input type="text" name="name" placeholder="Name*"  value={name}
                                onChange={(e)=>{setname(e.target.value) }} />
                  </div>
                  <div>
                    <label>Email Address</label>
                    <br></br>
                    <input
                      type="Email "
                      name="Email "
                      placeholder="Email Address*"
                      value={email}
                       onChange={(e)=>{setemail(e.target.value) }}
                    />
                  </div>
                </div>
                <div className="form_section_text_main">
                  <div>
                    <label>Subject</label>
                    <br></br>
                    <input
                     placeholder="Enter Subject"
                      name="msg_subject"
                      type="text"
                      value={msg_subject}
                       onChange={(e)=>{setmsg_subject(e.target.value) }}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <br></br>
                    <input
                     placeholder="Enter Phone"
                     id=""
                     name="phone_number"
                     type="text"
                     value={phone_number}
                     onChange={(e)=>{setphone_number(e.target.value) }}
                    />
                  </div>
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <label>Message</label>
                  <br></br>
                  <textarea placeholder="Message"  name="message"
                                defaultValue={""}
                                value={message}
                                onChange={(e)=>{setmessage(e.target.value) }} />
                </div>
                <button  type="button" onClick={()=>{ handalSubmit() }} >Submit</button>
              </form>
            </div>
            <div
              className="col-lg-6 location_section"
              style={{ padding: "0", margin: "0" }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3499.639788430641!2d77.117685!3d28.
              70042!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03c1266e5ae9%3A0x37cacfac2451d3ee!2sCarecone%20Technologies%20pvt
              .%20ltd.%20Mobile%20App%20Development%2C%20ios%20App%20Development%20Company%2C%20Software%20App%20Development%20
              company!5e0!3m2!1sen!2sin!4v1720521820550!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
