import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
function Navbar() {
  const buttonRef = useRef(null);
  const handleClick = () => {
    // Check if the button reference is available
    if (buttonRef.current) {
      // Simulate a click event on the button
      buttonRef.current.click();
    }
  };

  const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get("auth"));
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  const navigate = useNavigate();
  const [isVisible1, setIsVisible1] = useState(false);
  const hide1 = () => {
    setIsVisible1(true);
  };
  useEffect(() => {
    const timer = setTimeout(hide1, 10000);
    //  return () => clearTimeout(timer);

    setIsAuthenticated(!!Cookies.get("auth"));
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLogout = (e) => {
    e.preventDefault();
    Cookies.remove("token");
    Cookies.remove("auth");
    Cookies.remove("data");
    setIsAuthenticated(false);
    navigate("/");
  };
  let nevbar = "white";
  let text = "black";
  let home = "black";
  let Aboutus = "black";
  let Portfolio = "black";
  let Career = "black";
  let Contact = "black";
  let Blog = "black";
  let service = "black";
  let product = "black";
  if (activeLink === "/") {
    nevbar = "black";
    home = "orangered";
    Aboutus = "white";
    Portfolio = "white";
    Career = "white";
    Contact = "white";
    service = "white";
    Blog = "white";
    text = "white";
    product = "white";
  } else if (activeLink === "/about-us") {
    nevbar = "white";
    home = "black";
    Aboutus = "orangered";
    Portfolio = "black";
    Career = "black";
    Contact = "black";
    service = "black";
    Blog = "black";
  } else if (activeLink === "/portfolio") {
    nevbar = "white";
    home = "black";
    Aboutus = "black";
    Portfolio = "orangered";
    Career = "black";
    Contact = "black";
    service = "black";
    Blog = "black";
  } else if (activeLink === "/career") {
    nevbar = "white";
    home = "black";
    Aboutus = "black";
    Portfolio = "black";
    Career = "orangered";
    Contact = "black";
    service = "black";
    Blog = "black";
  } else if (activeLink === "/contact-us") {
    nevbar = "white";
    home = "black";
    Aboutus = "black";
    Portfolio = "black";
    Career = "black";
    Contact = "orangered";
    service = "black";
    Blog = "black";
  } else if (activeLink === "/blog") {
    nevbar = "white";
    home = "black";
    Aboutus = "black";
    Portfolio = "black";
    Career = "black";
    Contact = "black";
    service = "black";
    Blog = "orangered";
  } else if (activeLink.includes('/our-products')) {
    nevbar = "white";
    home = "black";
    Aboutus = "black";
    Portfolio = "black";
    Career = "black";
    Contact = "black";
    service = "black";
    Blog = "black";
    product = "orangered";
  }
  if (isVisible1 && activeLink === "/") {
    nevbar = "white";
    home = "orangered";
    Aboutus = "black";
    Portfolio = "black";
    Career = "black";
    Contact = "black";
    service = "black";
    Blog = "black";
    text = "black";
    product = "black";
  }
  //  console.log("activeLink----->", activeLink);
  //  console.log("product----->", product);

  // console.log("isVisible1----->", isVisible1);
  // console.log("nevbar----->", nevbar);
   //console.log("home----->", home);
  // console.log("Aboutus----->", Aboutus);
  // console.log("Portfolio----->", Portfolio);
  // console.log("Career----->", Career);
  // console.log("Contact----->", Contact);
  // console.log("Blog----->", Blog);
  const trackoId = 'reptile_tracko';
  const MobileId = 'enterprise-mobility-management/';

  return (
    <Fragment>
      <>
        <nav
          className="navbar"
          id="navbar_section"
          style={{ background: nevbar }}
        >
          <div className="container-fluid navbar_section_main_box">
            <Link to="/">
              <a className="navbar-brand">
                <img
                  src="/assets/HomeIimages/Reptile_R_Logo/Reptile Logo-01.png"
                  alt=""
                />
              </a>
            </Link>
            <button
              ref={buttonRef}
              className="navbar-toggler"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
            >
              <img
                src="/assets/HomeIimages/Reptile_R_Logo/menu_9684706.png"
                alt=""
              />
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex={-1}
              id="offcanvasNavbar"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                  Best Technologay
                </h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                />
              </div>
              <div></div>

              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-center flex-grow-1 pe-3">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" onClick={handleClick}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about-us"
                      className="nav-link"
                      onClick={handleClick}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={handleClick}>
                      Services
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/portfolio"
                      className="nav-link"
                      onClick={handleClick}
                    >
                      PortFolio
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to="/career"
                      className="nav-link"
                      onClick={handleClick}
                    >
                      Career
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact-us"
                      className="nav-link"
                      onClick={handleClick}
                    >
                      Contact
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/blog" className="nav-link" onClick={handleClick}>
                      Blogs
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="nevbar_section">
              <ul>
                <Link to="/">
                  <li
                    className="nav-item"
                    id="color_item"
                    style={{ color: home }}
                  >
                    Home
                  </li>
                </Link>
                <Link to="/about-us">
                  <li className="nav-item" style={{ color: Aboutus }}>
                    {/* <a
                      aria-current="page"
                      id="Abut"
                      style={{ color: Aboutus }}
                    > */}
                    About Us
                    {/* </a> */}
                  </li>
                </Link>
                {/* <Link to="/Services"> */}
                  <li className="nav-item" style={{ color: service }}>
                    Service
                    <i className="fa fa-caret-down" aria-hidden="true" />
                    <div className="dropdowan_menubar">
                      <div
                        className="container-fluid dropdowan_menu"
                        style={{ paddingTop: "34px", marginTop: "0" }}
                      >
                        <div className="row">
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4>Mobile App Development</h4>
                              <p>IOS Development</p>
                              <p>Android App</p>
                              <p>Flutter App</p>
                              <p>React Native</p>
                              <p>Hybrid App</p>
                              <p>OTT App Development</p>
                              <p>Education App Development</p>
                            </div>
                          </div>
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4> Web Development</h4>
                              <p>PHP</p>
                              <p>Laravel</p>
                              <p>React Js</p>
                              <p>CakePHP</p>
                              <p>Node JS</p>
                              <p>Codeigniter</p>
                              <p>TypeScript</p>
                            </div>
                          </div>
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4>Ecommerce Development</h4>
                              <p>Magento</p>
                              <p>Shopify</p>
                              <p>Wordpress</p>
                              <p>Custom Ecommerce</p>
                              <p>Opencart</p>
                            </div>
                          </div>
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4>Digital Marketing</h4>
                              <p>SEO</p>
                              <p>SMO</p>
                              <p>Content Marketing</p>
                              <p>Paid Media Marketing</p>
                              <p>Video Marketing</p>
                              <p>Affiliate Marketing</p>
                              <p>Ecommerce Seo</p>
                            </div>
                          </div>
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4>Branding Services</h4>
                              <p>UI/UX</p>
                              <p>Logo Designing</p>
                              <p>Visual Designing</p>
                              <p>Brand&nbsp;Collateral</p>
                            </div>
                          </div>
                          <div className="col-2 images_drop_section">
                            <div className="app_development_section">
                              <h4>Cloud Computing Services</h4>
                              <p>Amazon Web Services</p>
                              <p>Google Cloud Services</p>
                              <p>Azure Cloud Services</p>
                              <p>Digital Ocean Cloud Services</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                {/* </Link> */}
                {/* <Link to="/Products"> */}
                <li className="nav-item" style={{ color: product }}>
                  Products
                  <i className="fa fa-caret-down" aria-hidden="true" />
                  <div className="dropdowan_menubar">
                    <div
                      className="container-fluid dropdowan_menu"
                      style={{ paddingTop: "34px", marginTop: "0" }}
                    >
                      <div
                        className="row"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div className="col-2 images_drop_section">
                          <div className="app_development_section">
                            <h4 style={{ textAlign: "center" }}>
                              Our Products
                            </h4>
                            <Link to={`/our-products/${trackoId}`}>
                              <p style={{ textAlign: "center" }}>
                                Reptile Tracko 
                              </p>
                            </Link>
                            <Link to={`/our-products/${MobileId}`}>
                             <p style={{ textAlign: "center" }}>
                             Reptile EMM
                            </p>
                            </Link>
                            {/* <p style={{ textAlign: "center" }}>
                              Reptile Bahikhata
                            </p>
                            <p style={{ textAlign: "center" }}>
                              EMI Security Plus
                            </p>
                            <p style={{ textAlign: "center" }}>EMI Security</p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* </Link> */}
                {/* <Link to="/portfolio">
                  <li className="nav-item" style={{ color: Portfolio }}>
                    PortFolio
                  </li>
                </Link> */}

                <Link to="/career">
                  <li className="nav-item" style={{ color: Career }}>
                    Career
                  </li>
                </Link>
                <Link to="/contact-us">
                  <li className="nav-item" style={{ color: Contact }}>
                    Contact
                  </li>
                </Link>
                <Link to="/blog">
                  <li className="nav-item" style={{ color: Blog }}>
                    Blogs
                  </li>
                </Link>
              </ul>
            </div>
            <div className="nevbar_section">
              <div className="fa_fa_icon">
                <div className="img">
                  <img src="/assets/HomeIimages/Reptile_R_Logo/7177378.png" />
                </div>
                <div className="text">
                  <p id="asking_number" style={{ color: text }}>
                    Ask something?
                  </p>
                  <p id="number" style={{ color: text }}>
                    +91 931157 2747
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </>
    </Fragment>
  );
}
export default Navbar;
